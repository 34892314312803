import type { StyleRule } from '@archipro-design/aria';
import { getScrollbarStyle, pxArrayToRem } from '@archipro-design/aria';
import { captionClassName } from '@archipro-design/aria';
import { buttonClassName, pxToRem } from '@archipro-design/aria';
import { getDvhStyles } from '~/modules/design-board/util';

export const LoginWrapper: StyleRule = ({ theme }) => ({
    ...getDvhStyles('height', '', 100),
    ...getScrollbarStyle(theme, '0'),
    background: theme.siteVariables.colors.white['100A'],
    position: 'relative',
    padding: pxArrayToRem([0, 18, 18, 18]),
    width: '100%',
    [theme.screen.laptop]: {
        height: '100%',
        padding: pxArrayToRem([0, 28, 40, 28]),
        width: pxToRem(574),
    },
});

export const LoginHeader: StyleRule = ({ theme }) => ({
    height: pxToRem(64),
    justifyContent: 'center',
    padding: pxArrayToRem([12, 0]),
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.siteVariables.colors.transparency.black['10%']}`,
    marginBottom: pxToRem(18),
    [theme.screen.laptop]: {
        marginBottom: pxToRem(28),
    },
    [`& .${captionClassName}`]: {
        margin: '0 auto',
        alignSelf: 'center',
    },
    [`& .${buttonClassName}`]: {
        position: 'absolute',
        right: pxToRem(5),
        [theme.screen.laptop]: {
            right: pxToRem(15),
        },
    },
});

export const Subheader: StyleRule = () => ({
    textAlign: 'center',
    marginBottom: pxToRem(36),
});

export const LoginMessage: StyleRule = () => ({
    maxWidth: pxToRem(330),
    margin: 'auto',
    marginTop: pxToRem(-16),
});

export const FormLink: StyleRule = ({ theme }) => ({
    [`& .${captionClassName}`]: {
        color: theme.siteVariables.colors.charcoal[250],
    },
});
