import {
    Caption,
    Image,
    Logo,
    pxToRem,
    useStyles,
    useTheme,
} from '@archipro-design/aria';
import { Link, useSearchParams } from '@remix-run/react';
import * as S from './LoginPage.style';
import type { LoginSignUpProps } from '../component/login-component/LoginSignUp';
import LoginSignUp from '../component/login-component/LoginSignUp';
import { PolicyLink } from '../component/policy-link/PolicyLink';
import { useConfigContext } from '@archipro-website/config/bindings/react';

interface LoginPageProps {
    login: Partial<LoginSignUpProps>;
}

export const LoginPage = (props?: LoginPageProps) => {
    const theme = useTheme();
    const config = useConfigContext();
    const { css } = useStyles();

    const [searchParams] = useSearchParams();

    const skipPostSignUpRedirect =
        !!props?.login?.skipPostSignUpRedirect ||
        searchParams.get('skipPostSignUp') === '1';

    return (
        <div className={css(S.PageWrapper)}>
            <Image
                src={`${config.assetsBaseUrl}/assets/website/images/ui/page_403_background.png`}
                width={1920}
                height={1080}
                layout="fill"
                alt="login"
                objectFit="cover"
            />
            <div className={css(S.ContentWrap)}>
                <div className={css(S.Content)}>
                    <div className={css(S.LogoWrapper)}>
                        <Logo
                            as={Link}
                            to={'/'}
                            showFull
                            variables={{
                                logoPrimaryColor:
                                    theme.siteVariables.colors.primitive.white,
                                logoWidth: pxToRem(300),
                                logoHeight: 'auto',
                            }}
                        />
                    </div>

                    <div className={css(S.LoginWrapper)}>
                        <LoginSignUp
                            {...props?.login}
                            authSource="loginPage"
                            skipPostSignUpRedirect={skipPostSignUpRedirect}
                        />
                    </div>
                </div>

                <div className={css(S.Links)}>
                    <PolicyLink as="a" />
                    <Caption variant="02" as={Link} to="/become-a-pro">
                        ArchiPro for Business
                    </Caption>
                    <Caption variant="02" as="a" href="/contact-us">
                        Contact Us
                    </Caption>
                </div>
            </div>
        </div>
    );
};
